@use './variables';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');

.carbon-context {
  .page-container {
    .header {
      background-color: variables.$ui-05;
      color: variables.$ui-01;

      .brand h4 {
        color: variables.$ui-01 !important;
      }

      .thumbnail-wrapper,
      .profile-dropdown-toggle {
        color: variables.$ui-01;
      }
    }
  }
}

.ramen-dashboard-wrapper,
.configurations-wrapper,
.price-adjustments-dialog,
.parameters-dialog,
.msrp-reason-dialog,
.magic-override-expose-wrapper {
  font-family: 'IBM Plex Sans', sans-serif;

  .carbon-btn {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    align-items: center;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: space-between;
    letter-spacing: 0.16px;
    line-height: 1.28572;
    margin: 0;
    max-width: 20rem;
    min-height: 3rem;
    outline: none;
    padding: calc(0.875rem - 3px) 63px calc(0.875rem - 3px) 15px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9),
    box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9),
    outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
    vertical-align: top;

    .carbon-btn-icon {
      position: absolute;
      height: 1rem;
      right: 1rem;
      width: 1rem;
    }

    &.carbon-icon-only {
      padding: calc(0.875rem - 3px) 25px;
    }

    &:disabled {
      color: variables.$disabled-02;
      border: 1px solid variables.$disabled-02;
      cursor: not-allowed;
      background-color: transparent;
    }
  }

  .carbon-btn-flat {
    background-color: transparent;

    &.carbon-inverse {
      color: variables.$inverse-link;
    }
  }

  .carbon-btn-tertiary-white {
    color: variables.$text-04;
    background: transparent;
    border: 1px solid variables.$text-04;
  }

  .carbon-btn-primary {
    background-color: variables.$interactive-01;
    color: variables.$text-04;

    &:hover {
      background-color: variables.$hover-primary;
    }

    &:active {
      background-color: variables.$active-primary;
    }

    &:focus:not(:active) {
      box-shadow: inset 0 0 0 1px variables.$interactive-01, inset 0 0 0 2px variables.$field-01;
    }

    &:disabled {
      color: variables.$disabled-03;
      border-color: variables.$disabled-02;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  .carbon-btn-secondary {
    background-color: variables.$ui-05;
    color: variables.$text-04;

    &:hover {
      background-color: variables.$hover-secondary;
    }

    &:active {
      background-color: variables.$hover-secondary;
    }

    &:focus:not(:active) {
      box-shadow: inset 0 0 0 1px variables.$ui-05, inset 0 0 0 2px variables.$field-01;
    }

    &:disabled {
      color: variables.$disabled-03;
      background-color: variables.$disabled-02;
      border-color: variables.$disabled-02;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  .carbon-btn-warning {
    background-color: variables.$ui-06;
    color: variables.$text-04;
    white-space: nowrap;

    &:hover {
      background-color: variables.$hover-warning;
    }

    &:active {
      background-color: variables.$hover-warning;
    }

    &:focus:not(:active) {
      box-shadow: inset 0 0 0 1px variables.$ui-06, inset 0 0 0 2px variables.$field-01;
    }

    &:disabled {
      color: variables.$disabled-03;
      background-color: variables.$disabled-02;
      border-color: variables.$disabled-02;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  .carbon-btn-tertiary {
    background-color: #0000;
    border: 1px solid variables.$interactive-01;
    color: variables.$interactive-01;
    max-height: 40px;
    min-height: 0;

    &-sm {
      max-height: 32px;
      max-width: 120px;
    }

    &:hover {
      background-color: variables.$hover-primary;
      color: variables.$text-04;
    }

    &:active, &.active {
      background-color: variables.$active-primary;
      color: variables.$text-04;
    }

    &:focus:not(:active):not(.carbon-toggle-btn) {
      box-shadow: inset 0 0 0 1px variables.$interactive-01, inset 0 0 0 2px variables.$field-01;
      background-color: variables.$interactive-01;
      color: variables.$text-04;
    }

    &:disabled {
      color: variables.$disabled-03;
      background-color: variables.$disabled-02;
      border-color: variables.$disabled-02;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  .carbon-btn-quaternary {
    background-color: variables.$ui-01;
    height: 32px;
    min-height: 0;
    margin-right: 8px;
    color: variables.$text-01;
    padding: 7px 16px;
  }

  .carbon-link {
    color: variables.$link-01;
    cursor: pointer;
  }

  // carbon multiselectName
  .carbon-multiselect {
    background-color: variables.$field-01;
    color: variables.$text-01;
    padding: 0;
    height: 40px;
    max-height: none !important;
    min-width: 150px;
    border: none;
    border-bottom: 1px solid variables.$ui-04;

    &.carbon-select-xl {
      height: 48px;
    }

    &.tagless-select {
      ::ng-deep .k-reset {

        .k-button {
          background-color: transparent !important;
          background-image: none !important;
          border: none !important;

          > .delete {
            display: none;
          }
        }
      }
    }
  }

  .carbon-select-input-wrap {
    position: relative;
    width: 100%;

    .carbon-select {
      cursor: pointer;
    }

    i {
      position: absolute;
      right: 1rem;
      bottom: 0.6rem;
      pointer-events: none;
    }
  }

  .carbon-select, .aircraft-category-field .carbon-select {
    background-color: variables.$field-01;
    color: variables.$text-01;
    padding: 0 40px 0 16px;
    height: 40px;
    max-height: none !important;
    min-width: 150px;
    border: none;
    border-bottom: 1px solid variables.$ui-04;

    &.carbon-select-xl {
      height: 48px;
    }
  }

  .carbon-dropdownlist-wrap {
    position: relative;

    .carbon-dropdownlist {
      height: 40px;
      max-height: none !important;
      min-width: 150px;
      border: none;
      border-bottom: 1px solid variables.$ui-04 !important;

      span ::selection, li:hover {
        background-color: variables.$interactive-01;
      }

      li:hover {
        color: variables.$field-01 !important;
      }

      &.carbon-dropdownlist-xl {
        height: 48px;
      }

      &.k-widget.k-dropdown {
        box-shadow: none !important;
      }

      .k-dropdown-wrap {
        background-color: variables.$field-01 !important;
        color: variables.$text-01;
        display: flex;
        align-items: center;
        padding: 0 40px 0 16px;
        border: none;
        box-shadow: none;

        .k-select {
          display: none;
        }
      }

      .carbon-splitbutton {
        background-color: variables.$field-01 !important;
        border: none;
        box-shadow: none;
        justify-content: flex-start;
        padding-left: 16px;
      }
    }

    i {
      position: absolute;
      right: 1rem;
      bottom: 0.8rem;
      pointer-events: none;

      &.dashboard-layout-chevron {
        bottom: 0.6rem;
      }
    }

    .copy {
      i {
        position: static;
      }
    }
  }

  .carbon-dateinput {
    height: 40px;
    max-height: none !important;

    input {
      cursor: pointer;
    }

    &.carbon-dateinput-xl {
      height: 48px;

      span.k-state-focused {
        outline: 2px solid variables.$interactive-01 !important;
        outline-offset: -2px;
      }
    }

    &.k-dateinput .k-dateinput-wrap {
      .k-input::selection {
        background-color: variables.$interactive-01;
      }

      background-color: variables.$field-01;
      color: variables.$text-01;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      border: none;
      border-bottom: 1px solid variables.$ui-04;
    }

    .k-dateinput .k-dateinput-wrap {
      .k-input::selection {
        background-color: variables.$interactive-01;
      }
    }

  }

  .carbon-label,
  .form-group .carbon-label {
    font-family: 'IBM Plex Sans VF', sans-serif;
    letter-spacing: 0.32px;
    align-items: flex-start;
    color: variables.$text-02;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px !important;
    text-transform: none;
  }

  .carbon-toggle-switch {
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 1.5rem;
    position: relative;
    min-width: 90px;
    justify-content: center;

    &.switch-align-start {
      justify-content: flex-start;
    }

    &.hide-labels {
      .k-switch-label-on,
      .k-switch-label-off {
        display: none;
      }
    }

    .k-switch-on .k-switch-label-off {
      color: transparent;
    }

    .k-switch-label-on,
    .k-switch-label-off {
      right: auto;
      max-width: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-transform: none;
    }

    .k-switch-label-on {
      margin-left: 30px;
    }

    .k-switch-label-off {
      margin-left: 35px;
    }



    &.k-switch-on .k-switch-container {
      color: variables.$ui-04;
      background-color: variables.$support-02;
    }

    &.k-switch-off .k-switch-container {
      color: variables.$ui-04;
      background-color: variables.$ui-03;
    }

    &.k-switch-on .k-switch-handle {
      left: calc(100% - 10px);
    }

    .k-switch-container {
      width: 32px;
      height: 16px;
      display: flex;
      align-items: center;
      flex: none;
      padding: 0 3px;
      border: 2px solid white;

      &:focus:not(:active) {
        outline: 1px solid variables.$interactive-01;
      }

      .k-switch-handle {
        width: 10px;
        height: 10px;
      }
    }

    &.k-switch-on {
      .k-switch-handle {
        &:after {
          content: '✓';
          color: variables.$support-02;
          position: absolute;
          top: -1px;
          left: 1px;
          font-size: 8px;
        }
      }
    }
  }

  .carbon-date-input-wrap {
    position: relative;

    ::selection {
      background-color: #0353e9;
    }

    i {
      position: absolute;
      right: 1rem;
      bottom: 0.8rem;
      pointer-events: none;
    }

    .k-datepicker .k-select {
      background-color: variables.$ui-background;
    }
  }

  .carbon-table {
    th {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }

    &__condensed {
      table-layout: fixed;

      th {
        font-family: "IBM Plex Sans";
        fxont-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: normal;
        color: #161616 !important;
        background-color: #E0E0E0 !important;
        text-transform: none;
        padding: 7px 6px;
        white-space: normal;
        vertical-align: middle;
        min-width: 50px;

        &:first-child {
          padding-left: 10px !important;
        }
      }

      tbody tr td {
        padding: 10px;
      }
    }

  }

}

.carbon-daterange-popup-multiviewcalendar {
  font-family: 'IBM Plex Sans VF', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: variables.$ui-01;

  .k-calendar {
    &-content, &-tbody {
      background-color: variables.$ui-01;
    }
  }

  &.k-calendar {
    padding-bottom: 20px;

    .k-calendar-table:not(:last-child) {
      &:after {
        content: '';
        height: 100%;
        width: 1px;
        background-color: #000;
        position: absolute;
        top: 0;
        right: -8px;
      }
    }

    &.k-widget.k-calendar-range {
      .k-calendar-td {
        color: variables.$text-01;
        width: 2.5em;
        height: 2.5em;

        &.k-other-month .calendar-cell-container .date-value {
          color: variables.$text-05;
        }

        &.k-range-mid {
          background-color: variables.$highlight;
          background-image: none;
        }

        .calendar-cell-container .date-value {
          position: static !important;
          color: variables.$text-01;
        }

        .k-link {
          box-shadow: none;
          width: 4em;
          height: 4em;
          border-radius: 0;

          .calendar-cell-container {
            &:focus {
              outline: 2px solid variables.$interactive-01;
            }

            &.in-range .date-value {
              color: variables.$text-01;
              text-shadow: none;
            }

            .actual-value {
              background-color: variables.$interactive-01;
              opacity: 0.5;
            }
          }
        }

        &.k-range-start .k-link .calendar-cell-container.in-range .date-value,
        &.k-range-end .k-link .calendar-cell-container.in-range .date-value {
          color: variables.$text-04;
          text-shadow: none;
        }
      }
    }

    &.k-calendar-range .k-range-start .k-link,
    &.k-calendar-range .k-range-end .k-link {
      background-color: variables.$interactive-01;
      background-image: none;
      color: variables.$text-04;
    }

    .k-content,
    .k-calendar-content {
      .k-today .k-link {
        border-radius: 0;

        .calendar-cell-container {
          .date-value {
            color: variables.$interactive-01;
          }

          &:after {
            background-color: variables.$interactive-01;
            bottom: 0.4375rem;
            content: '';
            display: block;
            height: 0.25rem;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 0.25rem;
            z-index: 2;
          }
        }
      }

      .k-link .calendar-cell-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.carbon-dropdown-list {
  background-color: variables.$ui-01;

  .k-list .k-item {
    height: 40px;

    .svg-icon {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 9px;
    }

    &.k-state-selected {
      background-color: variables.$interactive-01;
    }
  }

  &-item {
    i {
      margin-right: 9px;
    }
  }
}


.carbon-popover {
  .k-popup {

    .k-tooltip.k-widget {
      background-color: rgba(57, 57, 57, 0.9) !important;
      font-family: IBM Plex Sans;
      padding: 4px 16px;
    }
  }

  .k-callout {
    color: rgba(57, 57, 57, 0.9) !important;
  }
}

.carbon-popover-wide {
  @extend .carbon-popover;
  max-width: initial;

  .k-popup {
    margin-left: 85px;
    max-width: initial;
  }
}

.carbon-checkbox {
  &.k-checkbox:checked,
  &.k-checkbox.k-checked {
    border-color: variables.$text-01;
    background-color: variables.$text-01;
  }
}

.carbon-tabs {
  .nav-wrap {
    width: auto;
  }

  .nav-tabs {
    padding-bottom: 0;
    border-bottom: 2px solid variables.$selected-ui;

    > li {
      > a {
        text-transform: none;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: variables.$ui-05;
      }
    }

    .active-bar {
      bottom: -2px;
      border-bottom: 2px solid variables.$interactive-01;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  .tab-wrapper {
    &.linetriangle {
      .tab-content-wrapper {
        border: none;
      }
    }
  }
}

.carbon-dialog {
  .k-dialog-titlebar {
    color: variables.$text-01;
    background-color: transparent;
    border: none;

    .k-window-title {
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .k-window-content,
  .k-window {
    background-color: variables.$ui-background;
  }

  .k-dialog-buttongroup {
    padding: 0;
    gap: 0;
    grid-gap: 0;

    > button {
      flex: 1;
    }
  }
}

.carbon-notification {
  width: calc(100vw - 81px) !important;
  left: 66px;
  border-width: 1px;
  border-left-width: 3px;
  padding-bottom: 15px;
  padding-left: 14px;
  margin: 4px 0;

  .k-notification-wrap {
    padding-top: 10px;

    .icon-close {
      padding-top: 2px;
    }

    .k-i-close {
      color: variables.$overlay-01;
    }

    .k-notification-content {
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: variables.$overlay-01;

      &:before {
        position: absolute;
        content: "Success notification";
        width: 152px;
        height: 81px;
        left: 46px;
        font-weight: 600;
        top: 14px;
      }
    }
  }

  &-success {
    background: variables.$notification-success-background-color;
    border-color: #94d7a7;
    border-left-color: variables.$support-02;


    .k-notification-wrap {

      .k-i-success {
        color: variables.$support-02;
      }

      .k-notification-content {
        padding-left: 145px;

        &:before {
          content: "Success notification";

        }
      }
    }
  }

  &-error {
    background: variables.$ui-background;
    border-color: #f09da1;
    border-left-color: variables.$danger;

    .k-notification-wrap {

      .k-i-error {
        color: variables.$danger;
      }

      .k-notification-content {
        padding-left: 125px;

        &:before {
          content: "Error notification";
        }
      }
    }
  }

  &-warning {
    background: variables.$ui-background;
    border-color: #f9e292;
    border-left-color: #e2b619;

    .k-notification-wrap {

      .k-i-warning {
        color: #e2b619;
      }

      .k-notification-content {
        padding-left: 145px;

        &:before {
          content: "Warning notification";

        }
      }
    }
  }


  &-info {
    background: #EDF5FF;;
    border-color: #8eaeeb;
    border-left-color: variables.$support-04;

    .k-notification-wrap {

      .k-i-info {
        color: variables.$support-04;
        padding-left: 15px;

        &::after {
          content: "\f05a";
        }
      }

      .k-notification-content {
        padding-left: 120px;

        &:before {
          content: "Info notification";

        }
      }
    }
  }

  &.carbon-notification-height {
    height: 64px;

    .k-notification-wrap .k-notification-content {
      padding-left: 11px;
      padding-top: 18px;
    }
  }

  &-toast {
    border-width: 1px;
    border-left-width: 3px;
    min-height: 128px;
    min-width: 288px;

    .k-notification-wrap {
      padding-top: 10px;

      .k-notification-content {
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: variables.$overlay-01;
        padding-left: 23px;
        padding-top: 18px;

        &:before {
          position: absolute;
          content: "Success notification";
          width: 152px;
          height: 81px;
          left: 50px;
          font-weight: 600;
          top: 14px;
        }

        .k-i-close {
          color: variables.$overlay-01;
        }
      }
    }
  }
}

.carbon-rangeslider {
  &.k-slider {
    .k-slider-selection {
      background-color: variables.$overlay-01;
      border-color: variables.$overlay-01;
      margin-top: -1px;
      height: 2px;
    }

    .k-draghandle {
      background-color: variables.$overlay-01;
      border-color: variables.$overlay-01;
      width: 12px;
      height: 12px;
    }
  }

  .k-slider-track {
    height: 2px;
  }
}

.datepicker {
  .k-calendar .k-state-selected .k-link {
    background-color: variables.$interactive-01;
  }
}

.carbon-type-toggler {
  .item-active {
    background-color: #000000;
    color: variables.$text-04;
  }

  button {
    height: 32px;
    margin-right: 0;
    color: variables.$text-02;
    background-color: variables.$disabled-01;;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
}

.carbon-datepicker {
  .k-state-selected {
    .k-link {
      background-color: variables.$interactive-01;
    }
  }
}

.ramen-main-dashboard {
  .k-tooltip.k-widget {
    background-color: variables.$interactive-01 !important;

    .k-callout.k-callout-s {
      color: variables.$interactive-01;
    }
  }

  .grid-cell-tooltip {
    .k-tooltip.k-widget {
      background-color: rgba(57, 57, 57, 0.9) !important;
      font-family: IBM Plex Sans;
      padding: 4px 16px;
    }

    .k-callout {
      color: rgba(57, 57, 57, 0.9);
    }
  }
}

.carbon-confirmation-dialog {
  .k-dialog {
    background-color: variables.$hover-ui;
    font-family: 'IBM Plex Sans', sans-serif;

    .k-dialog-title {
      font-size: 18px;
      line-height: 1.1;
    }

    .modal-header {
      .modal-title {
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 18px;
        line-height: 1.1;

      }

      button {
        opacity: 1;

        i {
          font-size: 15px !important;
          font-weight: bold;
          color: #161616;
        }
      }
    }

    .modal-body {
      button {
        border-radius: 0;
      }

      button:first-child {
        background-color: variables.$interactive-01;
        color: variables.$text-04;

        &:hover {
          background-color: variables.$hover-primary;
        }

        &:active {
          background-color: variables.$active-primary;
        }

        &:focus:not(:active) {
          box-shadow: inset 0 0 0 1px variables.$interactive-01, inset 0 0 0 2px variables.$field-01;
        }
      }

      button:last-child {
        background-color: variables.$ui-05;
        color: variables.$text-04;

        &:hover {
          background-color: variables.$hover-secondary;
        }

        &:active {
          background-color: variables.$hover-secondary;
        }

        &:focus:not(:active) {
          box-shadow: inset 0 0 0 1px variables.$ui-05, inset 0 0 0 2px variables.$field-01;
        }

        &:disabled {
          color: variables.$disabled-03;
          background-color: variables.$disabled-02;
          border-color: variables.$disabled-02;
          box-shadow: none;
          cursor: not-allowed;
        }
      }
    }
  }
}

.k-dropdownlist-without-default {
  .k-list-optionlabel {
    display: none;
  }
}

.activate-select{
  .k-input {
    padding-left: 19px;
  }
}

.tech-stop {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid var(--danger);
  padding: 2px 6px;
  margin: 5px 5px 0 0;
  background-color: var(--white);
  color: var(--danger);
  text-align: center;
  font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
  cursor: default;
  text-transform: uppercase;
}
