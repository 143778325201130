:root {
  /* global css variables available for whole app */
  --white: #fff;
  --accent: #d8353b;
  --danger: #f55753;
  --primary: #d8353b;
  --success: #FF5C49;
  --danger-lighter: #fddddd;
  --gray-lighter: #f0f0f0;
  --gray-light: #d9d9d9;
}

@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import '../node_modules/@fortawesome/fontawesome-pro/css/all.css';
// Kendo accent color
$accent: #d8353b;
@import "~@progress/kendo-theme-default/scss/all";
@import 'assets/pages/styles/index.scss';

@page {
  size: auto
}

.dashboard-chart {
  height: 100%;
}

.k-tooltip {
  color: #ffffff !important;
  background-color: var(--primary) !important;

  .k-callout {
    color: var(--primary);
  }
}


.k-window-content, .k-prompt-container  {
  min-width: 500px;
  min-height: 250px;
}

.dashboard-grid {
  .grid-cell {
    &.selected-column {
      background: #CFE0FF;
      .k-input:not(:focus) {
        background: #CFE0FF !important;
      }
    }
    &:hover {
      background: #CFE0FF !important;
      .k-input:not(:focus) {
        background: #CFE0FF !important;
      }
    }
  }
  .grid-input {
    .k-input {
      height: auto !important;
      text-overflow: initial;
      padding: 4px;
      background: none !important;
      &:focus {
        background-color: #fff !important;
      }
    }
  }
}

.clear-button {
  cursor: pointer;
  color: #656565;
  opacity: 0.5;
  position: absolute;
  top: 10px;
  right: 35px;

  &:hover {
  opacity: 1;
  }
}

/* flag icon styles */
.fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.fi:before {
  content: '\00a0';
}
.fi-us {
  background-image: url(assets/img/flags/us.svg);
}
.fi-eu {
  background-image: url(assets/img/flags/eu.svg);
}
.fi-gb {
  background-image: url(assets/img/flags/gb.svg);
}
.fi-ca {
  background-image: url(assets/img/flags/ca.svg);
}

.tooltip-style {
  $tooltip-bg-color: #0a0a0a;
  .k-tooltip {
    max-width: 300px;
    background-color: $tooltip-bg-color !important;
    padding: 10px;
    text-align: justify;
  }

  .k-callout {
    color: $tooltip-bg-color !important;
  }
}

// overwrite default tabstrip styles
kendo-tabstrip {
  &.k-tabstrip.only-tabstrip .k-content {
    border-bottom: none;
    padding: 0;
  }
}

.card {
  &.card-default.with-tabs {
    border-top: none;
  }
}

.icon-btn {
  margin-right: 4px;
  background: transparent;
  border: 0;
  height: 20px;
}
