// Colors
$ui-background: #ffffff;
$ui-01: #f4f4f4;
$ui-02: #fffffff;
$ui-03: #e0e0e0;
$ui-04: #8d8d8d;
$ui-05: #161616;
$ui-06: #da1e28;
$interactive-01: #0f62fe;
$interactive-02: #393939;
$interactive-03: #0f62fe;
$interactive-04: #0f62fe;

$text-01: #161616;
$text-02: #525252;
$text-03: #a8a8a8;
$text-04: #ffffff;
$text-05: #6f6f6f;
$text-error: #da1e28;
$text-warning: #FF4141;
$link-01: #0f62fe;
$icon-01: #161616;
$icon-02: #525252;
$icon-03: #ffffff;
$field-01: #f4f4f4;
$field-02: #ffffff;
$inverse-01: #ffffff;
$inverse-02: #393939;
$support-01: #da1e28;
$support-02: #24a148;
$support-03: #f1c21b;
$support-04: #0043ce;
$notification-error-background-color: #fff1f1;
$notification-success-background-color: #defbe6;
$notification-warning-background-color: #fff8e1;
$notification-info-background-color: #0043ce;
$inverse-support-01: #fa4d56;
$inverse-support-02: #42be65;
$inverse-support-03: #f1c21b;
$inverse-support-04: #4589ff;
$inverse-link: #78a9ff;
$focus: #0f62fe;
$overlay-01: #161616;
$hover-primary: #0353e9;
$hover-secondary: darken($ui-05, 10%);
$hover-warning: darken($ui-06, 10%);
$danger: #da1e28;
$hover-primary-text: #054ada;
$hover-secondary: #4c4c4c;
$hover-tertiary: #0353e9;
$hover-ui: #e5e5e5;
$hover-row: #e5e5e5;
$hover-danger: #ba1b23;
$active-primary: #002d9c;
$active-secondary: #6f6f6f;
$active-tertiary: #002d9c;
$active-ui: #c6c6c6;
$active-danger: #750e13;
$selected-ui: #e0e0e0;
$highlight: #d0e2ff;
$visited-link: #8a3ffc;
$disabled-01: #f4f4f4;
$disabled-02: #c6c6c6;
$disabled-03: #8d8d8d;
$skeleton-01: #e5e5e5;
$skeleton-02: #c6c6c6;
$hover-selected-ui: #cacaca;
$inverse-hover-ui: #4c4c4c;
$inverse-focus-ui: #ffffff;
